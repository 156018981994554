import React from 'react';

const faqData = [
    {
        id: 'integrating-screenshot-api',
        question: 'How do I integrate the screenshot API with my website or application?',
        answer: 'We provide detailed documentation and code examples to help you integrate the screenshot API with your website or application. Our support team is also available to provide assistance and answer any questions you may have.',
    },
    {
        id: 'supported-programming-languages',
        question: 'What programming languages are supported by the screenshot API?',
        answer: 'The screenshot API can be used with a variety of programming languages, including JavaScript, Python, PHP, and Ruby, among others. We also provide code examples and documentation to help you get started.',
    },
    {
        id: 'file-formats-supported',
        question: 'What file formats are supported for exporting screenshots?',
        answer: 'Our screenshot API supports exporting screenshots in various file formats, including PNG, JPG, WebP and PDF.',
    },
    {
        id: 'screenshot-capture-time',
        question: 'How long does it take to capture a screenshot?',
        answer: 'The time it takes to capture a screenshot can vary depending on the website or application being captured, as well as the rendering options selected. However, in most cases, the process takes only a few seconds.',
    },
    {
        id: 'maximum-resolution',
        question: 'What is the maximum resolution for screenshots?',
        answer: 'Our screenshot API supports capturing screenshots at high resolutions, with a maximum resolution of 1920x1080 pixels.',
    },
    {
        id: 'limit-on-api-requests',
        question: 'Is there a limit to the number of requests I can make to the screenshot API?',
        answer: 'We do not have a hard limit on the number of requests you can make to the screenshot API, but we may monitor usage and contact customers who are making excessive requests.',
    },
    {
        id: 'capture-of-authenticated-pages',
        question: 'Is it possible to capture screenshots of pages that require authentication or login?',
        answer: 'Yes, our screenshot API can capture screenshots of pages that require authentication or login, as long as you provide valid credentials.',
    },
    {
        id: 'custom-rendering-options',
        question: 'Can I specify custom rendering options or capture multiple screenshots in a single request?',
        answer: 'Yes, our screenshot API supports a wide range of custom rendering options, and you can capture multiple screenshots in a single API request.',
    },
    {
        id: 'limit-on-screenshot-storage-and-export',
        question: 'Is there a limit to the number of screenshots I can store or export?',
        answer: 'There is no hard limit on the number of screenshots you can store or export, but we may monitor usage and contact customers who are using excessive storage or bandwidth.',
    },
    {
        id: 'technical-support-and-documentation',
        question: 'What kind of technical support or documentation is available for the screenshot API?',
        answer: 'We offer comprehensive technical support and documentation for the screenshot API, including code examples, tutorials, and a dedicated support team to answer any questions or address any issues you may encounter.',
    },
    {
        id: 'pricing-plan-available',
        question: 'What pricing plans are available for the screenshot API?',
        answer: 'We offer several pricing plans for the screenshot API, with different tiers available depending on the number of screenshots allowed per month and the features included.',
    },
    {
        id: 'cost-to-use-api',
        question: 'How much does it cost to use the screenshot API?',
        answer: 'The cost of using the screenshot API depends on the pricing tier you choose, with higher-priced tiers offering more screenshots and additional features.',
    },
    {
        id: 'features-included-in-each-plan',
        question: 'What features are included in each pricing tier?',
        answer: 'Each pricing tier includes a different number of screenshots allowed per month, with higher-priced tiers also including additional features such as priority email support, custom rendering options, and dedicated technical support.',
    },
    {
        id: 'usage-measurement-and-charges',
        question: 'How is usage measured and charged for the screenshot API?',
        answer: 'Usage is measured based on the number of screenshots taken per month, with higher-priced tiers allowing more screenshots. Charges are billed monthly based on the pricing tier selected.',
    },
    {
        id: 'additional-fees-and-charges',
        question: 'Are there any additional fees or charges beyond the monthly subscription cost?',
        answer: 'We do not charge any additional fees or charges beyond the monthly subscription cost. However, if you exceed the number of screenshots allowed for your pricing tier, additional charges may apply.',
    },
    {
        id: 'changing-or-upgrading-pricing-plan',
        question: 'Can I change or upgrade my pricing plan at any time?',
        answer: 'Yes, you can change or upgrade your pricing plan at any time. If you upgrade to a higher-priced plan, any unused screenshots from your previous plan will be carried over.',
    },
    {
        id: 'free-trial-available',
        question: 'Is there a free trial available for the screenshot API?',
        answer: 'Yes, we offer a free 24 hour trial for the screenshot API, with a limited number of screenshots available for testing purposes.',
    },
    {
        id: 'discounts-and-promotions',
        question: 'Are there any discounts or promotions available for the screenshot API?',
        answer: 'We occasionally offer discounts or promotions for the screenshot API, which will be communicated to our customers via email or on our website.',
    },
    {
        id: 'accepted-payment-methods',
        question: 'What payment methods are accepted for the screenshot API?',
        answer: 'We accept various payment methods, including credit card and PayPal.',
    },
    {
        id: 'billing-frequency',
        question: 'How often will I be billed for the screenshot API?',
        answer: 'You will be billed monthly for the screenshot API, with charges automatically deducted from your chosen payment method.',
    },
    {
        id: 'integrating-screenshot-api',
        question: 'How do I integrate the screenshot API with my website or application?',
        answer: 'We provide detailed documentation and code examples to help you integrate the screenshot API with your website or application. Our support team is also available to provide assistance and answer any questions you may have.',
    },
    {
        id: 'supported-programming-languages',
        question: 'What programming languages are supported by the screenshot API?',
        answer: 'The screenshot API can be used with a variety of programming languages, including JavaScript, Python, PHP, and Ruby, among others. We also provide code examples and documentation to help you get started.',
    }
    ];


const FaqQuestionOLD = () => {
    return (
       <div className="sc-faq-pages-area sc-pt-130 sc-md-pt-70 sc-pb-10 sc-md-pb-10">
           <div className="container">
               <div className="row">
                   <div className="col-lg-6 offset-lg-3">
                       <div className="sc-heading text-center sc-mb-60">
                           <h2 className="sc-mb-20">Frequently Asked Questions</h2>
                           <div className="description">
                               Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati quae quo iste ipsum officiis deleniti asperiores sit.
                           </div>
                       </div>
                   </div>
                   <div className="col-lg-8 offset-lg-2">
                       <div className="accordion sc-faq-style" id="accordionExample">
                           {faqData.map((item) => (
                              <div className="accordion-item" key={item.id}>
                                  <h2 className="accordion-header" id={item.id}>
                                      <button
                                         className="accordion-button"
                                         type="button"
                                         data-bs-toggle="collapse"
                                         data-bs-target={`#${item.id}`}
                                         aria-expanded="false"
                                         aria-controls={item.id}
                                      >
                                          {item.question}
                                      </button>
                                  </h2>
                                  <div id={item.id} className="accordion-collapse collapse" aria-labelledby={item.id} data-bs-parent="#accordionExample">
                                      <div className="accordion-body">
                                          <strong>{item.answer}</strong>
                                      </div>
                                  </div>
                              </div>
                           ))}
                       </div>
                   </div>
               </div>
           </div>
       </div>
    )
}
const FaqQuestion = () => {
    return (
        <>
            <div className="sc-faq-pages-area sc-pt-130 sc-md-pt-70 sc-pb-10 sc-md-pb-10">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3">
                            <div className="sc-heading text-center sc-mb-60">
                                <h2 className="sc-mb-20">Frequently Asked Questions</h2>
                                <div className="description">Welcome to the Screenshots.Dev FAQ page! Here you can find comprehensive information about the features, usage, and pricing of our screenshot and PDF generation service. We provide answers to frequently asked questions, including technical details, customization options, and more. Our FAQ page is designed to help you get started with generating high-quality screenshots and PDFs of web pages using our API. Explore the page to learn more about how Screenshots.Dev can help you capture web page content in a snap!</div>
                            </div>
                        </div>
                        <div className="col-lg-8 offset-lg-2">
                            <div className="accordion sc-faq-style" id="accordionExample">
                                {faqData.map((item, index) => (
                                   <div className="accordion-item" key={item.id}>
                                       <h2 className="accordion-header" id={`heading${index}`}>
                                           <button
                                              className="accordion-button"
                                              type="button"
                                              data-bs-toggle="collapse"
                                              data-bs-target={`#collapse${index}`}
                                              aria-expanded={index === 0 ? "true" : "false"}
                                              aria-controls={`collapse${index}`}
                                           >
                                               {item.question}
                                           </button>
                                       </h2>
                                       <div id={`collapse${index}`} className={`accordion-collapse collapse${index === 0 ? " show" : ""}`} aria-labelledby={`heading${index}`} data-bs-parent="#accordionExample">
                                           <div className="accordion-body">
                                               <strong>{item.answer}</strong>
                                           </div>
                                       </div>
                                   </div>
                                ))}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FaqQuestion;
