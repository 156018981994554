import React from 'react';

const Accordion = () => {
    return (
        <>
            <div className="accordion sc-faq-style" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne">
                            Do I have to pay for each screenshot?
                        </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong
                            >
                                No, you do not have to pay for each screenshot with our screenshot API. Instead, we offer a pricing model based on the number of screenshots allowed per month, with different pricing tiers available depending on the volume of screenshots needed. You can choose the pricing tier that best fits your needs and budget, and then use the screenshot API to capture the allowed number of screenshots per month without incurring any additional costs.
    <br/><br/>
                                Additionally, if you have specific needs or requirements that are not covered by our standard pricing tiers, you can contact us to discuss custom pricing options. We strive to be transparent about our pricing and features, and provide clear communication to our customers about any changes or updates to our pricing plans.                            </strong>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo">
                            Are there any additional fees or charges beyond the monthly subscription cost?
                        </button>
                    </h2>
                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong
                            >We do not charge any additional fees or charges beyond the monthly subscription cost. However, if you exceed the number of screenshots allowed for your pricing tier, you will be rate-limited.</strong>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree">
                            Can I change or upgrade my pricing plan at any time?
                        </button>
                    </h2>
                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong
                            >Yes, you can change or upgrade your pricing plan at any time. If you upgrade to a higher-priced plan, any unused screenshots from your previous plan will be carried over.</strong>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFour"
                            aria-expanded="false"
                            aria-controls="collapseFour">Is there a free trial available for the screenshot API?
                        </button>
                    </h2>
                    <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong
                            >Yes, we offer a free 24 hour trial for the screenshot API, with a limited number of screenshots available for testing purposes.</strong>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                        <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseFive"
                            aria-expanded="false"
                            aria-controls="collapseFive">How often will I be billed for the screenshot API?
                        </button>
                    </h2>
                    <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                        <div className="accordion-body">
                            <strong
                            >You will be billed monthly for the screenshot API, with charges automatically deducted from your chosen payment method.</strong></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Accordion;
