import React from 'react';
import { Fade } from 'react-reveal';
import featureImg7 from '../../../assets/images/feature/feature_img7.png'
import {Link} from "react-router-dom";

const ThanksDetails = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                        <Fade left>
                            <div className="col-lg-6">
                                <div className="sc-feature-image pulse sc-md-mb-50">
                                    <img className="feature_img" src={featureImg7} alt="Feature" />
                                    <div className="image-bg-circle"></div>
                                </div>
                            </div>
                        </Fade>
                        <Fade right>
                            <div className="col-lg-6">
                                <div className="sc-feature-item">
                                    <div className="sc-heading-area sc-mb-20">
                                        <span className="sc-sub-title primary-color">Thank You!</span>
                                        <h2 className="heading-title sc-mb-25">We're excited to see what you build.</h2>
                                        <div className="description sc-mb-25 d-none">
                                            An Email has been sent with your API Key.
                                        </div>
                                    </div>
                                    <div className="feature_check">
                                        <ul>
                                            <li><i className="ri-checkbox-circle-fill"></i>An Email has been sent with your API Key.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Your 24 hour trial has begun.</li>
                                            <li><i className="ri-checkbox-circle-fill"></i>Login to change your plan at any time.</li>
                                        </ul>
    
                                        <div className="sc-primary-btn sc-md-pt-40 sc-mt-40">
                                            <Link to="/docs">
                                                <button className="primary-btn2"><span>API Docs</span></button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ThanksDetails;
